// .support-main-wrapper {
//     .group-chat-container {
//         .group-list-wrapper {
//             padding-inline: 0px;
//             max-height: 805px;
//             min-height: 505px;
//             overflow-y: auto;
//             overflow-x: hidden;

//             &::-webkit-scrollbar {
//                 width: 8px;
//             }

//             /* Track */
//             &::-webkit-scrollbar-track {
//                 background: transparent;
//                 border-radius: 5px;
//             }

//             /* Handle */
//             &::-webkit-scrollbar-thumb {
//                 background: #888;
//                 border-radius: 5px;
//             }

//             /* Handle on hover */
//             &::-webkit-scrollbar-thumb:hover {
//                 background: #555;
//             }

//             .group-chat-nav-wrapper {
//                 z-index: 999;
//                 background-color: #F0F0F0;

//                 .nav {
//                     &.nav-tabs {
//                         .nav-link {
//                             padding-inline: 10px;
//                         }
//                     }
//                 }

//                 .create-ticket-btn {
//                     font-family: Segoe UI;
//                     font-size: 16px;
//                     font-weight: 600;
//                     line-height: 22px;
//                     text-align: left;
//                     color: #FFFFFF;
//                     padding: 8px 26px;
//                     background-color: #496673;
//                     border-radius: 100px;
//                     border: 1px solid #496673;
//                 }
//             }

//             // .search-wrapper{
//             //     top: 100px;
//             // }
//             .list-group {

//                 .list-group-item {
//                     padding: 0px;

//                     .chat-info-wrapper {
//                         margin-block: 10px;
//                         border-radius: 10px;

//                         .request-name {
//                             font-family: Segoe UI;
//                             font-size: 18px;
//                             font-weight: 700;
//                             line-height: 23.94px;
//                             text-align: left;
//                             color: #525E9D
//                         }

//                         .request-ticketnumber {
//                             font-family: Segoe UI;
//                             font-size: 17px;
//                             font-weight: 700;
//                             line-height: 22.61px;
//                             text-align: left;
//                             color: #333333;
//                         }

//                         .msg-preview {
//                             font-family: Segoe UI;
//                             font-size: 14px;
//                             font-weight: 400;
//                             line-height: 18.62px;
//                             color: #333333;
//                             padding-left: unset;
//                             display: -webkit-box;
//                             /* Enables the flexbox container for truncation */
//                             -webkit-line-clamp: 2;
//                             /* Limits the text to 2 lines */
//                             -webkit-box-orient: vertical;
//                             /* Specifies the box orientation as vertical */
//                             overflow: hidden;
//                             /* Hides the overflowing content */
//                             text-overflow: ellipsis;
//                             /* Adds ellipsis when the text exceeds */
//                             max-height: 3em;
//                             /* Restricts the height to show 2 lines */
//                             line-height: 1.5em;
//                             word-break: break-all;
//                         }
//                     }
//                 }
//             }
//         }

//         .message-list-wrapper {
//             .message-list-header {
//                 border-bottom: 1px solid #D9D9D9;
//                 padding-bottom: 10px;

//                 .close-btn {
//                     font-family: Segoe UI;
//                     font-size: 16px;
//                     font-weight: 600;
//                     line-height: 18.62px;
//                     text-align: left;
//                     color: #B10E1C;
//                     border: 1px solid #B10E1C;
//                     border-radius: 15px;
//                     padding: 5px 12px;
//                     background: transparent;
//                     svg{
//                         font-size: 18px;
//                         line-height: 18px;
//                         margin-right: 5px;

//                     }
//                 }

//                 .reopen-btn {
//                     font-family: Segoe UI;
//                     font-size: 16px;
//                     font-weight: 600;
//                     line-height: 18.62px;
//                     text-align: left;
//                     color: #3A809E;
//                     border: 1px solid #3A809E;
//                     padding: 5px 12px;
//                     background: transparent;
//                     border-radius: 15px;

//                     svg {
//                         transform: rotate(-90deg);
//                         font-size: 18px;
//                         line-height: 18px;
//                         margin-right: 5px;
//                     }
//                 }
//             }

//             .message-list-card {
//                 .card {
//                     .card-body {
//                         .list-group {
//                             height: 675px;

//                             .today-line-wrapper {
//                                 display: none;
//                             }

//                             .sender {
//                                 .sender__msg {
//                                     padding-inline: 20px;

//                                     .message-content {
//                                         font-family: Segoe UI;
//                                         font-size: 14px;
//                                         font-weight: 400;
//                                         line-height: 18.62px;
//                                         text-align: left;
//                                         color: #000000;
//                                     }

//                                     .uploaded-file-wrapper {
//                                         background-color: #FFFFFF;
//                                         border: 1px solid #C8CAC4;
//                                         border-radius: 4px;
//                                         width: fit-content;
//                                         // margin-top: 10px;

//                                         div {


//                                             span {
//                                                 padding: 5px;
//                                                 font-family: Segoe UI;
//                                                 font-size: 13px;
//                                                 font-weight: 400;
//                                                 line-height: 17.29px;
//                                                 text-align: left;
//                                                 color: #8F9195;
//                                             }

//                                             div.download-img-wrapper {
//                                                 background-color: #F0F0F0;
//                                                 border-top-right-radius: 4px;
//                                                 border-bottom-right-radius: 4px;
//                                                 padding: 3px;
//                                                 cursor: pointer;
//                                             }
//                                         }
//                                     }
//                                 }

//                                 .sender__info {
//                                     .sender__info--name {
//                                         font-family: Segoe UI;
//                                         font-size: 13px;
//                                         font-weight: 700;
//                                         line-height: 18.2px;
//                                         text-align: left;
//                                         color: #616161;
//                                     }

//                                     .sender__info--time {
//                                         font-family: Segoe UI;
//                                         font-size: 13px;
//                                         font-weight: 400;
//                                         line-height: 17.29px;
//                                         text-align: left;
//                                         color: #8F9195;
//                                     }
//                                 }
//                             }

//                             .receiver {
//                                 .receiver__info {
//                                     font-family: Segoe UI;
//                                     font-size: 13px;
//                                     font-weight: 700;
//                                     line-height: 18.2px;
//                                     text-align: left;
//                                     color: #616161;

//                                     .receiver__info--time {
//                                         font-family: Segoe UI;
//                                         font-size: 13px;
//                                         font-weight: 400;
//                                         line-height: 17.29px;
//                                         text-align: left;
//                                         color: #8F9195;
//                                     }
//                                 }

//                                 .receiver__msg {
//                                     padding-inline: 20px;

//                                     .message-content {
//                                         font-family: Segoe UI;
//                                         font-size: 14px;
//                                         font-weight: 400;
//                                         line-height: 18.62px;
//                                         text-align: left;
//                                         color: #000000;
//                                     }

//                                     .uploaded-file-wrapper {
//                                         background-color: #FFFFFF;
//                                         border: 1px solid #C8CAC4;
//                                         border-radius: 4px;
//                                         width: fit-content;
//                                         // margin-top: 10px;

//                                         div {


//                                             span {
//                                                 padding: 5px;
//                                                 font-family: Segoe UI;
//                                                 font-size: 13px;
//                                                 font-weight: 400;
//                                                 line-height: 17.29px;
//                                                 text-align: left;
//                                                 color: #8F9195;
//                                             }

//                                             div.download-img-wrapper {
//                                                 background-color: #F0F0F0;
//                                                 border-top-right-radius: 4px;
//                                                 border-bottom-right-radius: 4px;
//                                                 padding: 3px;
//                                                 cursor: pointer;
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }

//                     .card-footer {
//                         padding: 1rem 0.75rem;
//                         border-radius: 10px;
//                         border: 1px solid #D1D1D1;

//                         .send-msg {
//                             display: flex;
//                             justify-content: space-between;
//                             align-items: center;
//                             gap: 10px;

//                             .send-input-wrapper {
//                                 width: calc(100% - 100px);

//                                 .send-input-inner {
//                                     width: 100%;

//                                     input {
//                                         border: unset;
//                                         border-radius: unset;
//                                         box-shadow: unset;
//                                         width: 100%;

//                                         &:focus {
//                                             box-shadow: unset;
//                                         }

//                                         &::placeholder {
//                                             font-family: Segoe UI;
//                                             font-size: 15px;
//                                             font-weight: 400;
//                                             line-height: 20.25px;
//                                             color: #737376;
//                                         }
//                                     }

//                                     .form-control {
//                                         padding: unset;
//                                         border-radius: unset;
//                                     }
//                                 }

//                                 .file__upload--info {
//                                     border: 1px solid #D9D9D9;
//                                     border-radius: 4px;
//                                     padding: 0px;
//                                     height: auto;

//                                     .file-name {
//                                         font-family: Segoe UI;
//                                         font-size: 13px;
//                                         font-weight: 400;
//                                         line-height: 17.29px;
//                                         text-align: left;
//                                         color: #8F9195;
//                                         padding: 4px;
//                                     }

//                                     .trash-img-wrapper {
//                                         background-color: #F0F0F0;
//                                         padding: 4px;
//                                         // padding-top: 1px;
//                                         border-top-right-radius: 5px;
//                                         border-bottom-right-radius: 5px;
//                                         cursor: pointer;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             .create-ticket-wrapper {
//                 .heading {
//                     font-family: Segoe UI;
//                     font-size: 18px;
//                     font-weight: 600;
//                     line-height: 23.94px;
//                     text-align: left;
//                     color: #333333;
//                     padding-block: 10px;
//                     border-bottom: 1px solid #D9D9D9;
//                 }

//                 form {
//                     .create-ticket-label {
//                         font-family: Segoe UI;
//                         font-size: 16px;
//                         font-weight: 600;
//                         line-height: 22px;
//                         color: #242424;
//                     }

//                     textarea {
//                         border: 1px solid #D1D1D1;
//                         border-bottom: unset;
//                         border-bottom: 2px solid #616161;
//                         border-radius: 5px;

//                         &::placeholder {
//                             font-family: Segoe UI;
//                             font-size: 15px;
//                             font-weight: 400;
//                             line-height: 19.95px;
//                             text-align: left;
//                             color: #929292;
//                         }
//                     }

//                     .create-ticket-attachement-wrapper {
//                         .file__upload--info {
//                             border: 1px solid #D9D9D9;
//                             border-radius: 5px;
//                             padding: 0px;
//                             height: auto;

//                             .file-name {
//                                 font-family: Segoe UI;
//                                 font-size: 13px;
//                                 font-weight: 400;
//                                 line-height: 17.29px;
//                                 text-align: left;
//                                 color: #8F9195;
//                                 padding: 4px;
//                             }

//                             .file-delete {
//                                 background-color: #F0F0F0;
//                                 padding: 4px;
//                                 // padding-top: 1px;
//                                 border-top-right-radius: 5px;
//                                 border-bottom-right-radius: 5px;
//                                 cursor: pointer;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .support-faq-wrapper {
//         .heading {
//             font-family: Segoe UI;
//             font-size: 24px;
//             font-weight: 700;
//             line-height: 22px;
//             text-align: left;
//             color: #242424;
//             border-bottom: 1px solid #D9D9D9;
//             padding-block: 20px;
//         }

//         .upload_csv--user__faq {
//             .accordion {
//                 .accordion-item {
//                     background: #F0F0F0;
//                     padding: 20px;
//                     border-radius: 10px;
//                     border: 1px solid #F2F2F2;
//                     margin-block: 15px;

//                     .accordion-header {
//                         button {
//                             background-color: transparent;
//                             box-shadow: unset;
//                             font-family: Segoe UI;
//                             font-size: 16px;
//                             font-weight: 600;
//                             line-height: 22px;
//                             color: #242424;
//                             padding: 0px;

//                             &.collapsed {
//                                 &::after {
//                                     background-image: url("../../assets/images/Plus.png");
//                                     transform: unset;
//                                 }
//                             }

//                             &::after {
//                                 background-image: url("../../assets/images/Minus.png");
//                                 transform: unset;
//                             }
//                         }
//                     }

//                     .accordion-collapse {
//                         .accordion-body {
//                             background-color: #FFFFFF;
//                             font-family: Segoe UI;
//                             font-size: 16px;
//                             font-weight: 400;
//                             line-height: 25px;
//                             color: #242424;
//                             border-radius: 10px;
//                             margin-top: 10px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }


.group-chat-wrapper{

    border-radius: unset;
    .support-faq-btn {
            font-family: Segoe UI;
            font-size: 18px;
            font-weight: 600;
            line-height: 24.3px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #333333;
            background-color: transparent;
            border: unset;
            outline: unset;
            box-shadow: unset;
            margin-right: 15px;
        }
    .service--req-wrapper {
        &.group-chat-container{
        background-color: #FFFFFF;
        padding: 40px 0px;
        min-height: calc(-384px + 100vh);  // for footer design in user side
        .faq-offcanvas-wrapper {
                position: absolute;
                top: 0px;
                right: 0px;
                left: 0px;
                bottom: 0px;
                z-index: 9;
                border-radius: 25px;
                // box-shadow: 0px 4px 25px 0px #0000001A;
                background-color: #00000066;
                display: flex;
                justify-content: end;
        
                .faq-panel-left {
                    background-color: transparent;
                    width: 50%;
                }
        
                .faq-panel-inner {
                    width: 50%;
                    background-color: #FFFFFF;
                    border-top-right-radius: 25px;
                    border-bottom-right-radius: 25px;
                    padding: 20px;
                    position: relative;
                    height: 100%;
        
                    .header {
                        // padding-bottom: 15px;
        
                        .header-inner {
                            .contact-us-text{
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 22px;
                                color: #242424;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                margin-right: 10px;
                                a{
                                    text-decoration: underline;
                                    color: #0C3344;
                                }
                            }
                            .close-btn {
                                color: #8F9195;
                                background: transparent;
                                border: unset;
                                box-shadow: unset;
                            }
        
                            .main-heading {
                                font-family: Segoe UI;
                                font-size: 24px;
                                font-weight: 700;
                                line-height: 22px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #242424;
                            }
                        }
                    }
        
                    .faq-panel-body {
                        overflow-y: auto;
                        height: calc(100% - 70px);
                        padding-right: 5px;
                        margin-block: 15px;
        
                        &::-webkit-scrollbar {
                            width: 5px;
                        }
        
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: transparent;
                            border-radius: 5px;
                        }
        
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #D9D9D9;
                            border-radius: 5px;
                        }
        
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #D9D9D9;
                        }
        
                        .upload_csv--user__faq {
                            .accordion {
                                .section-heading {
                                        font-size: 20px;
                                        font-weight: 600;
                                        line-height: 22px;
                                        color: #242424;
                                        margin-block: 30px 20px;
                                    }
                                .accordion-item {
                                    background: #F0F0F0;
                                    padding: 20px;
                                    border-radius: 10px;
                                    border: 1px solid #F2F2F2;
                                    margin-block: 15px;
        
                                    &:first-child {
                                        margin-top: unset;
                                    }
        
                                    &:last-child {
                                        margin-bottom: unset;
                                    }
        
                                    .accordion-header {
                                        button {
                                            background-color: transparent;
                                            box-shadow: unset;
                                            font-family: Segoe UI;
                                            font-size: 16px;
                                            font-weight: 600;
                                            line-height: 22px;
                                            text-align: left;
                                            text-underline-position: from-font;
                                            text-decoration-skip-ink: none;
                                            color: #242424;
                                            padding: 0px;
                                            padding-right: 10px;
        
                                            &.collapsed {
                                                &::after {
                                                    background-image: url("../../assets/images/Plus.png");
                                                    transform: unset;
                                                }
                                            }
        
                                            &::after {
                                                background-image: url("../../assets/images/Minus.png");
                                                transform: unset;
                                            }
                                        }
                                    }
        
                                    .accordion-collapse {
                                        .accordion-body {
                                            background-color: #FFFFFF;
                                            font-family: Segoe UI;
                                            font-size: 16px;
                                            font-weight: 400;
                                            line-height: 25px;
                                            text-align: left;
                                            text-underline-position: from-font;
                                            text-decoration-skip-ink: none;
                                            color: #242424;
                                            margin-top: 15px;
                                            border-radius: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        


    .service-req--inner {
        .service-header {
            border-bottom: 1px solid #D9D9D9;
            margin-inline: 12px;

            .heading {
                font-family: Segoe UI;
                font-size: 24px;
                font-weight: 700;
                line-height: 22px;
                color: #242424;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

            }
        }

        .service-req-form-wrapper {
            // margin-top: 50px;

            .service-req-form {
                .form__field--wrapper {
                    padding: unset;
                    max-width: unset;
.search-icon{

}
                    .form-label {
                        font-family: Segoe UI;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        color: #242424;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                    }

                    .select-dropdown {
                        background-color: #FFFFFF;
                        padding: 8px 10px;
                        font-family: Segoe UI;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #333333;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                        border: 1px solid #D1D1D1;
                        border-bottom-color: #616161;
                        border-radius: 4px;
                        &.multi-select-dropdown{
                            border: unset;
                            border-bottom: 1px solid #616161;
                            padding: unset;
                            .css-13cymwt-control{
                                border: 1px solid #D1D1D1;
                                box-shadow: unset;
                                &:hover{
                                    border-color: #D1D1D1;
                                }
                                .css-1fdsijx-ValueContainer{
                                    padding-block: 4.5px;
                                    .css-1jqq78o-placeholder{
                                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #333333;
                                    }
                                    .css-1dimb5e-singleValue{
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 22px;
                                        color: #333333;
                                    }
                                }
                            }
                            .css-t3ipsp-control{
                                border: 1px solid #D1D1D1;
                                box-shadow: unset;
                                &:hover{
                                    border-color: #D1D1D1;
                                }
                                .css-1fdsijx-ValueContainer{
                                    padding-block: 4.5px;
                                    .css-1jqq78o-placeholder{
                                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #333333;
                                    }
                                    .css-1dimb5e-singleValue{
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 22px;
                                        color: #333333;
                                    }
                                }
                            }
                            .css-1nmdiq5-menu{
                                margin: unset;
                            }
                        }

                        &:focus {
                            border-bottom: 2px solid #0F6CBD;

                            &.is_invalid {
                                border-bottom: 2px solid #EC2323;
                            }
                        }

                        &:disabled {
                            background-color: #efefef;
                        }

                        &.is_invalid {
                            box-shadow: none;
                            border-bottom: 2px solid #EC2323;
                        }

                        .dropdown-toggle {
                            &::after {
                                content: none;
                            }
                        }

                        .dropdown-menu {
                            .dropdown-item {
                                &:active {
                                    color: #212529;
                                }

                                &:hover {
                                    color: #212529;
                                }
                            }

                            &.show {
                                .dropdown-item {
                                    &:active {
                                        color: #212529;
                                    }

                                    &:hover {
                                        color: #212529;
                                    }
                                }
                            }
                        }

                    }

                    .form-control {
                        &.service-req-input {
                            background-color: #FFFFFF;
                            padding: 8px 10px;
                            font-family: Segoe UI;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22px;
                            color: #333333;
                            text-underline-position: from-font;
                            text-decoration-skip-ink: none;
                            border: 1px solid #D1D1D1;
                            border-bottom-color: #616161;
                            border-radius: 4px;
                            &.add-inventory{
                                padding-left: 45px;
                            }

                            &::placeholder {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;
                                color: #333333;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                            }

                            border: 1px solid #D1D1D1;
                            border-bottom-color: #616161;
                            border-radius: 4px;

                            &:focus {
                                box-shadow: none;
                            }

                            &:focus {
                                border-bottom: 2px solid #0F6CBD;

                                &.is_invalid {
                                    border-bottom: 2px solid #EC2323;
                                }
                            }

                            &.is_invalid {
                                box-shadow: none;
                                border-bottom: 2px solid #EC2323;
                            }
                        }
                    }
                    .error-message {
                        color: #EC2323;
                        font-size: 0.875em;
                        margin-top: 0.25em;
                    }
    
                    .form-check {
                        .form-check-input {
                            width: 18px;
                            height: 18px;
                            border-radius: 2px;
                            margin-right: 12px;
    
                            &:checked {
                                background-color: #496673;
                                border-color: #496673;
    
                            }
    
                            &:focus {
                                box-shadow: none;
                                border-color: #dee2e6;
                            }
    
                        }
    
                        .form-check-label {
                            color: #333333;
                            font-size: 16px;
                            font-weight: 400;
    
                            span {
                                font-size: 20px;
                            }
                        }
                    }
    
                    [type=radio] {
                        &:checked+label {
                            &:before {
                                border: 2px solid #496673;
                            }
    
                            &:after {
                                background: #496673;
                            }
                        }
                    }
    
                    .custom-radio-label {
                        font-size: 14px;
                        font-weight: 600;
                        color: #333333;
                    }
                    .serched-inventory-modal{
                        // height: 500px;
                        // overflow: hidden;
                        position: relative;
                        z-index: 2;
                        box-shadow: 0px 12px 15px 0px #0000001A;
                        border: 1px solid #D1D1D1;
                        border-radius: 0 0 5px 5px;
                    .list-wrapper{
                        height: 365px;
                        overflow-y: auto;
                        &::-webkit-scrollbar {
                                            width: 5px;
                                        }
                            
                                        /* Track */
                                        &::-webkit-scrollbar-track {
                                            background: transparent;
                                            border-radius: 5px;
                                        }
                            
                                        /* Handle */
                                        &::-webkit-scrollbar-thumb {
                                            background: #D9D9D9;
                                            border-radius: 5px;
                                        }
                            
                                        /* Handle on hover */
                                        &::-webkit-scrollbar-thumb:hover {
                                            background: #D9D9D9;
                                        }
                        .list-item-wrapper{
                            background-color: #F0F0F0;
                            padding: 15px 10px;
                            border-bottom: 1px solid #D9D9D9;
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            .list-inventory-title{
                                color: #333333;
                                font-family: Segoe UI;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-bottom: 0px;
                                .list-lot-no{
                                    color: #333333;
                                font-family: Segoe UI;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
margin-bottom: 0px;
                                }
                            }
                            .barrel-count {
                                border-radius: 50%;
                                font-size: 16px;
                                line-height: 22px;
                                color: #737376;
                                padding: 5px;
                                height: 22px;
                                width: 22px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            } 
                        }
                    }
                    .searched-inventory-modal--right{
                        padding-inline: 20px;
                        .right-header{
                            padding-block: 20px;
                            border-bottom: 1px solid #D9D9D9;
                            .search-input-wrapper{
                                input{
                                    border: 1px solid #E4E4DD;
                                    background-color: #FFFFFF;
                                    border-radius: 100px;
                                    padding: 10px 10px 10px 45px;
                                    font-family: Segoe UI;
font-size: 15px;
font-weight: 600;
line-height: 22px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #616161;
width: 300px;
&::placeholder{
font-size: 15px;
font-weight: 500;
line-height: 22px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #616161;
}
                                }
                            }
                            .header-field-wrapper{
                                input[type="checkbox"]{

                                }
                                .header-field-label{
                                    font-family: Segoe UI;
font-size: 16px;
font-weight: 400;
line-height: 21.28px;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
margin-left: 5px;
                                }
                            }
                        }
                        .barrel-list-wrapper{
                            padding-block: 10px;
                            border-bottom: 1px solid #D9D9D9;
                            height: 230px;
                            overflow-y: auto;
                            &::-webkit-scrollbar {
                                width: 5px;
                            }
                
                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: transparent;
                                border-radius: 5px;
                            }
                
                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #D9D9D9;
                                border-radius: 5px;
                            }
                
                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #D9D9D9;
                            }

                        .inventery-barrel-wrapper{
                            .barrel-number--inner{
                                font-family: Segoe UI;
                                border: 1px solid transparent;
font-size: 15px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #616161;
background-color: #F7F7F7;
width: 100%;
border-radius: 5px;
cursor: pointer;
padding: 3px;
svg{
    height: 18px;
    width: 18px;
}
                            .barrel-number{
                                font-family: Segoe UI;
font-size: 15px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #616161;
                            }
                        }
                    }
                        }
                        .modal-footer-wrapper{
                            padding: 15px;
                            text-align: center;
                            .done-btn{
                                font-family: Segoe UI;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #3A809E;
border: 0;
box-shadow: 0;
outline: 0;
background-color: transparent;
display: flex;
align-items: center;
margin-inline: auto;
svg{
    height: 18px;
    width: 18px;
}
                            }
                        }
                    }
                }
                .selected-inventory-detail-wrapper{
                    .selected-inventory-detail-inner{
                    background-color: #F0F0F0;
                    margin-top: 15px;
                    border-radius: 10px;
                    .header-wrapper{
                        padding: 7px 15px;
                        border-bottom: 1px solid #D9D9D9;
                    .detail-heading{
                        font-family: Segoe UI;
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
                    }
                    .total-quantity{
                        font-family: Segoe UI;
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
span{
    font-weight: 700;
}
                    }
                    .remove-btn{
                        display: flex;
                        align-items: center;
                        background-color: transparent;
                        padding: 5px 10px;
                        border: 1px solid #737376;
                        box-shadow: unset;
                        cursor: pointer;
                        outline: 0;
                        font-family: Segoe UI;
font-size: 15px;
font-weight: 400;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #616161;
border-radius: 50px;
                    }
                }
                .total-barrel-list-wrapper{
                    max-height: 230px;
                    overflow-y: auto;
                    padding: 3px 15px 10px 15px;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
        
                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: transparent;
                        border-radius: 5px;
                    }
        
                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #D9D9D9;
                        border-radius: 5px;
                    }
        
                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #D9D9D9;
                    }
                    .bareels--list-wrapper{
                        .barrel-item-wrapper{
                            font-family: Segoe UI;
font-size: 15px;
font-weight: 400;
line-height: 22px;
letter-spacing: -0.02em;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #616161;
border: 1px solid #8F9195;
background-color: #FFFFFF;
border-radius: 5px;
padding: 2px 5px;
svg{
    height: 18px;
    width: 18px;
}
                        }
                    }
                }
            }
                }
                }

                .create-ticket-attachement-wrapper {
                    // margin-top: 25px;
                    color: #3A809E;
                    font-family: Segoe UI;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 21.28px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;

                    svg {
                        fill: #3A809E;
                    }

                    
                }
                .file__upload--info{
                    .file-name{
                        font-family: Segoe UI;
font-size: 14px;
font-weight: 400;
line-height: 18.62px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #616161;
text-overflow: ellipsis;
max-width: 245px;
overflow: hidden;
white-space: nowrap;
                    }
                    .file-delete{
                        background-color: #F7F7F7;
                        border: 1px solid #D9D9D9;
                        padding: 4px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        img{
                            height: 14px;
                            width: 14px;
                        }
                    }
                }


                .form__btn--group {
                    .secondary-btn {
                        padding: 12px 24px;
                        min-width: 125px;
                    }

                    .primary-btn {
                        padding: 12px 24px;
                        min-width: 125px;
                    }
                }
            }
            .req-footer-wrapper{
                border-top: 1px solid #D9D9D9;   
                padding: 20px;
                .error-wrapper{
                    max-width: fit-content;
                .error-header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;

                .file-error-heading{
                    font-family: Segoe UI;
font-size: 15px;
font-weight: 600;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #333333;
margin: 0;
display: flex;
align-items: center;
gap: 5px;
                }
                //styleName: Web/Subtitle 2;
                .close-error-btn{
                    display: flex;
                    align-items: center;
                    gap: 2px;
font-family: Segoe UI;
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
border: unset;
background-color: unset;
box-shadow: unset;
outline: unset;
color: #3A809E;
}
            }
        }
                .req-footer-inner {
                        justify-content: center;
                        display: flex;
                        align-items: center;
                    }
            }
        }
    }
}
    }

    .no-spinner {
        /* Chrome, Safari, Edge, Opera */
        -webkit-appearance: none;
        margin: 0;
      }
  
      .no-spinner::-webkit-outer-spin-button,
      .no-spinner::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      /* Firefox */
      .no-spinner {
        -moz-appearance: textfield;
      }
      
}

.private-sell-dropdown{
    border: 1px solid rgb(204, 204, 204);
    padding: 0px;
    margin: 0px;
    list-style: none;
    background-color: white;
    //position: absolute;
    width: 100%;
    z-index: 1;
    max-height: 135px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #D9D9D9;
    }
    .private-sell-dropdown-item{
    padding: 1px 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #333333;
    &:hover {
        background-color: #1967d2;
        color: white;
    }
}
}

.support-no-record-found {
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:#333333;
}