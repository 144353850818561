.profile__bar {
    background: #E4E4DD;
    border: 1px solid #E4E4DD;
    border-radius: 100px;
    min-width: max-content;
    max-width: 250px;

    .profile--pic {
        img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
        }
    }

    .profile--cart {
        .MuiButtonBase-root {
            background-color: #0C3344;
            color: #FFF;
            height: 50px;
            width: 50px;
        }
    }

    .profile--notification {
        .btn-basic {
            background-color: #0C3344;
            color: #FFF;
            height: 50px;
            width: 50px;
            // .arrow-icon{
            //     display: none;
            // }

            &::after {
                content: none;
            }

            .msg-count {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35%;
                right: 35%;
                transform: translate(50%, -50%);
                height: 16px;
                width: 16px;
                background-color: orange;
                font-size: 12px;
                border-radius: 50%;
            }
        }
        // .dropdown{
        //     &.show{
        //         .arrow-icon{
        //             display: block;
        //             position: absolute;
        //             right: -5px;
        //             bottom: -41px;
        //             font-size: 60px;
        //             color: #FFFFFF;
        //         }
        //     }
        // }
        
        

        .dropdown-menu {
            // inset-block-start: 10px !important;
            // .dropdown-inner{
            max-height: 250px;
            min-width: 350px;
            max-width: 430px;
            overflow: auto;

            // Custom scrollbar styles
            &::-webkit-scrollbar {
                width: 7px; // Width of the scrollbar
                height: calc(100% - 20px); // Height of the scrollbar (not typically set for vertical scrollbars)
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey; // Inset shadow for the track
                background-color: #f1f1f1; // Background color of the track
                border-radius: 10px; // Rounding the corners of the track
            }

            &::-webkit-scrollbar-thumb {
                background-color: #97AEB8; // Color of the thumb
                border-radius: 10px; // Round the corners of the thumb
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #496673; // Color of the thumb on hover
            }
        }
    }
    // }

    .profile__bar--info {
        .profile--name {
            .name {
                font-size: 14px;
                line-height: 19px;
                font-weight: 700;
            }

            .login--type {
                font-size: 12px;
                line-height: 19px;
            }
        }
    }

    .profile__bar--menu {
        cursor: pointer;

        .dropdown {
            .dropdown-toggle {
                border: unset;
                border-radius: 50%;
                background-color: #FFF;
                height: 34px;
                width: 34px;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                &::after {
                    content: none;
                }

                &.show {
                    border: unset;
                }
            }

            .dropdown-menu {
                position: absolute;
                top: 100%;
                left: 100%;
                transform: translate(-100%, 30%);

                .dropdown-item {
                    &:active {
                        background-color: #6F6F6F;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:575px) {
    .profile__bar .profile--notification .dropdown-menu {
        max-width: 250px;
    }
    // .profile__bar .profile--notification .dropdown-menu .dropdown-inner {
    //     max-width: 250px;
    // }
}