@import '../../sass/style.scss';

.Typesense__container {
    background-color: #F8F8F8;
    padding-top: 48px;
    padding-bottom: 48px;
    min-height: calc(100vh - 266px);

    .Typesense__filters-wrapper {
        background: $white;
        border-radius: 20px;
        max-width: 100%;

        .filters--type {

            .clear-filter-btn {
                    font-family: Segoe UI;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.62px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #3A809E;
                    background-color: transparent;
                    border: unset;
                    outline: unset;
                    box-shadow: unset;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    margin-bottom: -5px;
            
                    svg {
                        height: 16px;
                        width: 16px;
                    }
            }
            .filter {
                &.filter--border {
                    border-bottom: 1px solid #D9D9D9;
                }

                .filter--title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    color: $primaryTextColor;
                }

                .ais-RefinementList {
                    .ais-RefinementList-list {
                        padding-left: 0;

                        .ais-RefinementList-item {
                            margin-bottom: 0.5rem;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .ais-RefinementList-label {
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;

                                .ais-RefinementList-checkbox {
                                    border: 2px solid #49454F;
                                    margin-right: 8px;
                                }

                                .ais-RefinementList-labelText {
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: $primaryTextColor;
                                }

                                .ais-RefinementList-count {
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: $primaryTextColor;
                                    margin-left: .4rem;

                                    &::before {
                                        content: '(';
                                    }

                                    &::after {
                                        content: ')';
                                    }
                                }
                            }

                            &.ais-RefinementList-item--selected {
                                .ais-RefinementList-label {
                                    .ais-RefinementList-checkbox {
                                        &:checked {
                                            background: #0C3344;
                                            // background-color: red;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // Slider Css
                .BTW8mG_spectrum-Slider {
                    .BTW8mG_spectrum-Slider-controls {
                        margin-top: 24px;
                        margin-left: 0.5rem;
                        cursor: pointer;

                        .BTW8mG_spectrum-Slider-track {
                            height: 2px;
                            background-color: #9E874A;
                            cursor: pointer;
                        }

                        .BTW8mG_spectrum-Slider-handle {
                            height: 14px;
                            width: 14px;
                            border-radius: 50%;
                            background-color: #9E874A;
                        }
                    }
                }
                .spiritAge-range{
                    .ais-RangeInput-form{
                        .ais-RangeInput-label{
                            .ais-RangeInput-input{

                            }
                        }
                    }
                }
            }

            .ais-Panel {
                &.ais-Dropdown {
                    &.my-BrandDropdown {
                        margin: 0.25rem;

                        .ais-Panel-header {
                            .ais-Dropdown-button {
                                background-color: $white;
                                color: $primaryTextColor;
                                padding: 8px 16px;
                                font-weight: 600;
                                letter-spacing: 0.02em;
                                // border: 1px solid $spareWhite;
                                cursor: pointer;
                                appearance: none;
                                // background-color: #fff;
                                background-image: linear-gradient(-180deg, #fff, #fcfcfd);
                                border: 1px solid #d6d6e7;
                                border-radius: 3px;
                                box-shadow: inset 0 1px 4px 0 rgba(151, 174, 181, 0.3);
                                // color: #23263b;
                                user-select: none;

                                &:hover {
                                    background-image: linear-gradient(-180deg, #fff, #f5f5fa);
                                }

                                &:disabled[disabled] {
                                    background-image: linear-gradient(-180deg, #fff, #f5f5fa);
                                    border-color: #efeff5;
                                    box-shadow: none;
                                    color: #b6b7d5;
                                }
                            }
                        }

                        .ais-Panel-body {
                            .ais-RefinementList {
                                .ais-RefinementList-list {
                                    width: 20rem;
                                    display: flex;
                                    flex-wrap: wrap;
                                    padding-left: 0;
                                    margin-top: 16px;

                                    .ais-RefinementList-item {
                                        padding: 0.35rem 0.5rem 0.35rem 0.75rem;
                                        margin: 0.2rem;
                                        border-radius: 2em;
                                        background: #ccc;
                                        cursor: pointer;

                                        .ais-RefinementList-label {
                                            cursor: pointer;

                                            // padding: 8px;
                                            .ais-RefinementList-checkbox {
                                                display: none;
                                            }

                                            .ais-RefinementList-count {
                                                display: inline-flex;
                                                justify-content: center;
                                                align-items: center;
                                                background-color: $white;
                                                border-radius: 100px;
                                                padding: 4px;
                                                margin-left: 6px;
                                                font-size: 10px;
                                            }
                                        }

                                        &.ais-RefinementList-item--selected {
                                            background: $primaryTextColor;
                                            color: $white;

                                            .ais-RefinementList-label {
                                                .ais-RefinementList-count {
                                                    color: $primaryTextColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .ais-RangeInput {
                                .ais-RangeInput-form {
                                    .ais-RangeInput-label {
                                        .ais-RangeInput-input {
                                            -webkit-box-align: center;
                                            -ms-flex-align: center;
                                            align-items: center;
                                            background-color: #fff;
                                            border: 1px solid #d6d6e7;
                                            border-radius: 3px;
                                            box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, .3);
                                            caret-color: #5a5e9a;
                                            color: #23263b;
                                            cursor: text;
                                            display: -webkit-box;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-box-flex: 1;
                                            -ms-flex: 1;
                                            flex: 1;
                                            font-size: .875rem;
                                            height: 2rem;
                                            line-height: 1.25rem;
                                            min-width: 0;
                                            overflow: hidden;
                                            padding: 0 1rem;
                                            position: relative;
                                        }
                                    }

                                    .ais-RangeInput-separator {
                                        margin-inline: 0.5rem;
                                    }

                                    .ais-RangeInput-submit {
                                        appearance: none;
                                        background-color: #fff;
                                        background-image: linear-gradient(-180deg, #fff, #fcfcfd);
                                        border: 1px solid #d6d6e7;
                                        border-radius: 3px;
                                        box-shadow: 0 1px 0 0 rgba(35, 38, 59, .05);
                                        color: #23263b;
                                        cursor: pointer;
                                        display: inline-flex;
                                        font-size: .875rem;
                                        font-weight: 400;
                                        height: 2rem;
                                        justify-content: center;
                                        line-height: 1.25rem;
                                        padding: 0 1rem;
                                        place-items: center;
                                        text-decoration: none;
                                        user-select: none;
                                        margin-left: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .filter--matches {
                .ais-CurrentRefinements {
                    padding: 12px 10px 12px 10px;
                    border-radius: 15px;
                    border: 1px solid #97AEB8;
                    min-height: 60px;

                    .ais-CurrentRefinements-list {
                        list-style: none;
                        padding-left: unset;
                        margin-bottom: 0;

                        .ais-CurrentRefinements-item {
                            margin-bottom: 6px;
                            // display: inline-flex;
                            // padding: 6px 12px;
                            // background: #EDEDED;
                            // border-radius: 100px;
                            // max-width: 225px;
                            // margin: 4px;

                            .ais-CurrentRefinements-label {
                                display: block;
                                font-weight: 600;
                            }

                            .ais-CurrentRefinements-category {
                                display: inline-flex;
                                padding: 6px 12px;
                                background: #EDEDED;
                                border-radius: 100px;
                                max-width: 225px;
                                margin: 4px;

                                .ais-CurrentRefinements-categoryLabel {
                                    margin-inline: 6px;
                                }

                                .ais-CurrentRefinements-delete {
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }

            .filter--spiritAge {
                .spiritAge__wrapper {
                    border: 1px solid #D1D1D1;
                    border-radius: 10px;
                    padding: 15px;

                    .spiritAge-range {
                        .ais-RangeInput {
                            .ais-RangeInput-form {
                                .ais-RangeInput-label {
                                    border: 1px solid #D1D1D1;
                                    padding: 12px 16px;
                                    margin-right: 12px;

                                    .ais-RangeInput-input {
                                        border: unset;
                                    }
                                }

                                .ais-RangeInput-separator {
                                    // display: none;
                                    margin-right: 12px;
                                }

                                .ais-RangeInput-submit {
                                    border: unset;
                                    display: inline-flex;
                                    padding: 12px 14px;
                                }
                            }
                        }
                    }

                    .range__container--wrapper {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);

                        .range__container {
                            .css-t3ipsp-control {
                                min-height: 50px;
                            }

                            .css-13cymwt-control {
                                min-height: 50px;
                                // display: flex;
                                // align-items: stretch;

                                // .css-1fdsijx-ValueContainer {
                                //     .css-1dimb5e-singleValue {
                                //         display: flex;
                                //         align-items: center;
                                //         height: 100%;

                                //         .css-qbdosj-Input {
                                //             height: 100%;
                                //             align-items: center;
                                //         }
                                //     }
                                // }

                                // .css-1hb7zxy-IndicatorsContainer {
                                //     .css-1u9des2-indicatorSeparator {
                                //         display: none;
                                //     }
                                // }
                            }
                        }
                    }
                }
            }

            .filter--proof {
                .ais-RangeInput {
                    .ais-RangeInput-form {
                        .ais-RangeInput-label {
                            border: 1px solid #D1D1D1;
                            padding: 8px 10px;
                            margin-right: 12px;
                            border-radius: 10px;

                            .ais-RangeInput-input {
                                border: unset;
                            }
                        }

                        .ais-RangeInput-separator {
                            // display: none;
                            margin-right: 12px;
                        }

                        .ais-RangeInput-submit {
                            border: unset;
                            display: inline-flex;
                            padding: 10px 24px;
                            border-radius: 10px;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .filter--price {
                .BTW8mG_spectrum-Slider {
                    z-index: 0;
                    width: 100%;
                }
            }

            .clear--filter {
                .ais-ClearRefinements {
                    max-width: 75%;
                    margin: auto;
                }
            }
        }

        .ais-SearchBox {
            .ais-SearchBox-form {
                position: relative;

                .ais-SearchBox-input {
                    appearance: none;
                    background-color: #EAECEE;
                    border: 1px solid transparent;
                    border-radius: 100px;
                    // box-shadow: inset 0 1px 4px 0 rgba(119, 122, 175, .3);
                    // caret-color: #5a5e9a;
                    // box-shadow: inset 0 1px 4px 0 rgba(151, 174, 181, 0.3);
                    caret-color: #97aeb8;
                    color: #23263b;
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 0%;
                    flex: 1 1 0%;
                    max-width: 100%;
                    width: 100%;
                    padding: 0.5rem 0.5rem 0.5rem 2.5rem;

                    &::-webkit-search-cancel-button {
                        display: none;
                    }
                }

                .ais-SearchBox-submit {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                    padding: 8px;
                    margin-left: 5px;
                    border: none;
                    border-radius: 4px;
                    background-color: $transparent;
                    cursor: pointer;

                    &::placeholder {
                        color: $graniteGray;
                    }

                    .ais-SearchBox-submitIcon {
                        align-self: center;
                        width: 16px;
                        height: 16px;
                        fill: $graniteGray;
                    }
                }

                .ais-SearchBox-reset {
                    display: flex;
                    position: absolute;
                    top: 50%;
                    right: 4px;
                    transform: translateY(-50%);
                    padding: 8px;
                    margin-left: 5px;
                    border: none;
                    border-radius: 4px;
                    background-color: $transparent;
                    cursor: pointer;

                    &::placeholder {
                        color: $graniteGray;
                    }

                    .ais-SearchBox-resetIcon {
                        align-self: center;
                        width: 12px;
                        height: 12px;
                        fill: $graniteGray;
                    }
                }
                .ais-SearchBox-loadingIndicator{
                    display: none;
                }
            }
        }

        .ais-ClearRefinements {
            .ais-ClearRefinements-button {
                font-family: Segoe UI;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.62px;
                    text-align: left;
                    text-underline-position: from-font;
                    text-decoration-skip-ink: none;
                    color: #3A809E;
                    background-color: transparent;
                    border: unset;
                    outline: unset;
                    box-shadow: unset;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    margin-bottom: -3px;
            
                    svg {
                        height: 16px;
                        width: 16px;
                    }
                cursor: pointer;

                .clear-filters {
                    justify-content: center;

                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .table__heading {
        color: $darkTan;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .view__type--select {
        .btn-group {
            background-color: #E4E4DD;
            border-radius: 100px;
            padding: 4px;

            .btn {
                &.btn-link {
                    color: $primaryHeadingColor;
                    font-size: 14px;
                    text-decoration: none;
                    opacity: 1;
                    padding-right: 8px;
                }

                &.btn-primary {
                    background: $transparent;
                    border-color: $transparent;
                    border-radius: 50%;
                    color: #8699A2;
                    margin-right: 2px;
                    padding: 6px;

                    &.active {
                        background: #0C3344;
                        color: $white;

                        &:hover {
                            background: #0C3344;
                            color: $white;
                        }
                    }

                    &:hover {
                        background: #0C3344;
                        color: #0C3344;
                    }
                }
            }
        }
    }

    .inventory__table--container {
        .table-container {
            display: block;
            overflow-x: auto;
            white-space: nowrap;
            margin-bottom: 15px;

            // Custom scrollbar styles
            &::-webkit-scrollbar {
                // width: 7px; // Width of the scrollbar
                height: 4px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                background-color: #f1f1f1; // Background color of the track
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #97AEB8; // Color of the thumb
                border-radius: 10px; // Round the corners of the thumb
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #496673; // Color of the thumb on hover
            }

        }

        .table--header {
            overflow-x: hidden;
            /* Prevent scrollbar on the header */
            display: table;
            width: 100%;

            .table {
                font-size: 14px;
                width: 100%;

                thead {
                    tr {
                        border-bottom: 2px solid $darkTan;

                        th {
                            // width: 20%;
                            min-width: 175px;
                            padding: 1rem 0.5rem;

                            &:last-child {
                                min-width: 100px;
                                max-width: fit-content;
                            }

                            .thead__heading {
                                color: $primaryHeadingColor;
                            }

                            .ais-SortBy {
                                .ais-SortBy-select {
                                    // -webkit-appearance: none;
                                    // -moz-appearance: none;
                                    // appearance: none;
                                    /* Add a custom background and border */
                                    background-color: $chineseWhite;
                                    border: 1px solid $transparent;
                                    border-radius: 4px;
                                    padding-inline: 6px;
                                    // padding: 8px;
                                    /* Adjust padding as needed */
                                    // width: 200px;
                                    /* Set width as needed */

                                    option {
                                        background-color: $white;
                                        border: 1px solid $transparent;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .table--body {
            display: table;
            width: 100%;

            .table {
                font-size: 16px;
                width: 100%;

                tbody {
                    tr {
                        td {
                            // width: 20%;
                            min-width: 175px;
                            color: $primaryTextColor;
                            font-weight: 600;

                            &:last-child {
                                min-width: 100px;
                                max-width: fit-content;
                            }
                            .sold-out-label{
                                font-family: Segoe UI;
                                font-weight: 700;
                                font-size: 18px;
                                line-height: 22px;
                                letter-spacing: 0px;
                                text-align: center;
                                color:#FF8B03;
                                border: 2px solid #FF8B03;
                                padding: 8px 15px;
                                border-radius: 10px;
                                // rotate: -10deg;
                                width: fit-content;
                                // margin-top: 10px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .ais-Hits {
                        .ais-Hits-list {
                            padding: 0;
                            display: block;

                            .ais-Hits-item {
                                list-style: none;
                            }
                        }
                    }
                }
            }
        }

        .ais-Hits {
            .ais-Hits-list {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                padding-left: 0;
                .ais-Hits-item{
                    cursor: pointer;
                }
            }
        }

        .ais-Pagination {
            .ais-Pagination-list {
                .ais-Pagination-item {
                    display: inline-block;
                    margin-left: 6px;
                }

                .ais-Pagination-link {
                    font-weight: 700;
                    padding: 4px 8px;
                }

                .ais-Pagination-item--selected {
                    .ais-Pagination-link {
                        background-color: #9E874A;
                        color: #FFF;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}


/* Search */
.search-panel {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.search-panel__filters {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.search-panel__pagination {
    margin: 0 auto 1rem;
}

/* Custom category dropdown */
.my-CategoryDropdown .ais-HierarchicalMenu {
    height: 185px;
    overflow: auto;
}

.my-BrandDropdown .ais-RefinementList-label mark {
    background: none;
    text-decoration: underline;
    font-weight: bold;
}

.ais-Dropdown-button--refined {
    font-weight: bold;
}


// Custom Styles :
.grid-view {
    &.inventory__grid-view {
        display: block;
        padding: 15px;

        .grid-item {
            border: none;
            border-radius: 20px;

            .card {
                border: none;

                .card-img-top {
                    width: auto;
                    height: 220px;
                    object-fit: cover;
                    border-radius: 10px;
                }

                .card-body {
                    .card-title {
                        border-bottom: 1px solid#D9D9D9;

                        h5 {
                            margin-bottom: 1rem;
                            font-size: 20px;
                            font-weight: 700;
                        }
                    }

                    .card-text {
                        .grid-cell {
                            font-size: 16px;
                            font-weight: 700;
                            color: #9E874A;
                        }
                    }

                    .details {
                        font-size: 14px;
                        font-weight: 600;
                        color: #242424;
                    }
                    .sold-out-label{
                        font-family: Segoe UI;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: 0px;
                        text-align: center;
                        color:#FF8B03;
                        border: 2px solid #FF8B03;
                        padding: 8px 15px;
                        border-radius: 10px;
                        rotate: -10deg;
                        width: fit-content;
                        margin-top: 10px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1400px) {
    .grid-view.inventory__grid-view .grid-item .card .card-body .card-title h5 {
        font-size: 18px;
    }
}

.request__sample {
    .MuiButtonBase-root {
        height: 40px;
        width: 40px;
        min-width: unset;
        background: $white;
        padding: 10px;
        border-radius: 50%;
        box-shadow: none;
        margin-left: 0;

        &:hover {
            background-color: $antiFlashWhite;
        }
    }
}

.my-empty-class {
  text-align: center;
  color: #333;
  font-size: 18px;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.my-empty-class::before {
//   content: 'Loading Inventories for you! 😃';
//   display: block;
//   margin: 20px auto;
//   font-weight: bold;
content: '';
  display: block;
  margin-top: 10px;
  color: gray;
//   margin: 20px auto;
//   width: 50px;
//   height: 50px;
//   border: 5px solid #f3f3f3; /* Light grey */
//   border-top: 5px solid #9E874A; /* Blue */
//   border-radius: 50%;
//   animation: spin 1s linear infinite;
}

.my-empty-class::after {
  content: 'No products in Market!'; //Please Wait!
  display: block;
  margin-top: 10px;
  color: gray;
}
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width:1200px) {
    .Typesense__container {
        min-height: calc(100vh - 302px);;
    }
  }
  @media (max-width:1650px) {
    .Typesense__container .table__heading{
        font-size: 20px;
    }
    .grid-view.inventory__grid-view .grid-item .card .card-body .card-title h5 {
        font-size: 17px;
    }
    .grid-view.inventory__grid-view .grid-item .card .card-body .card-text .grid-cell {
        font-size: 15px;
    }
    .grid-view.inventory__grid-view .grid-item .card .card-body .details{
        font-size: 13px;
    }
    .grid-view.inventory__grid-view .grid-item .card .card-body .details .grid-cell {
        margin-bottom: 5px;
    }
    .Typesense__container .Typesense__filters-wrapper .filters--type .filter .filter--title {
        font-size: 15px;
    }
    .filter .BTW8mG_spectrum-Slider-labelContainer{
        font-size: 14px;
    }
    .Typesense__container .Typesense__filters-wrapper .filters--type .filter .ais-RefinementList .ais-RefinementList-list .ais-RefinementList-item .ais-RefinementList-label .ais-RefinementList-labelText {
        font-size: 14px;
    }
    .Typesense__container .inventory__table--container .table--body .table tbody tr td{
        font-size: 14px;
    }
  }
  .loading-root {
    text-align: center;
    color: #333;
    font-size: 18px;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

.loading-root::before {
    content: '';
    display: block;
    margin-top: 10px;
    color: gray;
    margin: 20px auto;
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #9E874A; /* Blue */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loading-root::after {
    content: 'Please wait!';
    display: block;
    //margin-top: 10px;
    color: gray;
}
@media (max-width:767px) {
    .Typesense__container .inventory__table--container .ais-Hits .ais-Hits-list{
        grid-template-columns:unset;
    }
}