@import '../../sass/style.scss';

.no-record {
    text-align: center;
    margin-top: 20px;
}

.dashboard {
    background-color: $brightGray;
    // border-radius: 35px 35px 0px 0px;
    height: auto;

    &__uploadInvBanner {
        .banner {
            position: relative;
            border-radius: 10px;
            background-image: url('./../../assets/images/products/BarrelFillImage.png');
            background-size: cover;
            background-position: center;
            min-height: 325px;
            display: flex;
            align-items: center;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // background-color: rgba(0, 0, 0, 0.5); 
                background: linear-gradient(356.85deg, rgba(255, 255, 255, 0) -0.62%, rgba(1, 1, 1, 0.5) 75.39%);
                z-index: 1;
                border-radius: 10px;
            }

            .banner-content {
                position: relative;
                z-index: 2;

                h4 {
                    line-height: 42px;
                }

                // .btn {
                //     border-color: #FFF;
                //     color: #FFF;

                //     &:hover,
                //     &:active {
                //         background-color: unset;
                //         color: #FFF;
                //         border: 2px solid #FFF;
                //     }
                // }
            }
        }
    }

    // &__companyProfile {
    //     height: calc(100vh - 250px);
    //     max-width: 300px;
    //     min-width: 300px;
    //     margin-top: 55px;
    //     margin-bottom: 3rem;
    //     // overflow: hidden;
    //     position: fixed;
    //     z-index: 1;

    //     .profile {
    //         height: 100%;
    //         background: #F0F0F0;
    //         border-radius: 20px;

    //         .company-details {
    //             transform: translateY(-55px);

    //             .company-name {
    //                 font-weight: 700;
    //                 font-size: 18px;
    //             }
    //         }

    //         .list-group {
    //             .list-group-item {
    //                 background-color: $transparent;
    //                 display: flex;
    //                 align-items: center;
    //                 border-left-width: 0px;
    //                 border-right-width: 0px;
    //                 border-radius: 0px;

    //                 // &:last-child {
    //                 //     border-bottom-width: 0px;
    //                 // }

    //                 .info {
    //                     .sub-title {
    //                         color: #496673;
    //                         font-size: 13px;
    //                         font-weight: 700;
    //                     }

    //                     .detail {
    //                         color: #242424;
    //                         font-size: 15px;
    //                         font-weight: 700;
    //                     }
    //                 }
    //             }
    //         }

    //         .collapse-btn {
    //             background: $white;
    //             border-radius: 50%;
    //             height: 40px;
    //             width: 40px;
    //         }
    //     }
    // }

    &__user {
        &--title {
            color: $primaryTextColor;
            font-weight: 600;
            font-size: 25px;
        }

        &--companyInfo {
            .dropdown {
                .dropdown-toggle {
                    &::after {
                        content: none;
                    }
                }

                .btn-info {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 40px;
                    background-color: $white;
                    border-radius: 50%;
                    border: none;
                    outline: none;
                }

                .dropdown-menu {
                    box-shadow: 0px 10px 30px 0px #00000026;
                    border-radius: 25px;
                    background-color: $white;
                    min-width: 300px;
                    max-width: 300px;
                }

                .list-group {
                    .list-group-item {
                        background-color: $transparent;
                        display: flex;
                        align-items: center;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        border-radius: 0px;

                        &:first-child {
                            border-top-width: 0px;
                        }

                        &:last-child {
                            border-bottom-width: 0px;
                        }

                        .company-details {
                            .company-name {
                                font-weight: 700;
                                font-size: 18px;
                            }
                        }


                        .info {
                            .sub-title {
                                color: #496673;
                                font-size: 13px;
                                font-weight: 700;
                            }

                            .detail {
                                color: #242424;
                                font-size: 15px;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }

    &__inner {
        gap: 32px;
        background: $white;
        padding: 25px;
        border-radius: 25px;
        // height: 390px;
    // overflow-y: auto;

        &.custom-dashboard-inner{
            background-color: transparent;
            padding: 0;
        }
    }    

    &__data {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        gap: 10px;
        grid-auto-rows: auto;

        // @include media-between(xl, xxl) {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @include media-between(md, xl) {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @include media-between(sm, md) {
        //     grid-template-columns: repeat(3, 1fr);
        // }

        // @include media-max(sm) {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        &--card {
            background: $white;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1 1 calc(20% - 10px);
            box-shadow: 0px 4px 20px 0px #0000001A;
            padding: 12px 15px;
            border-radius: 15px;
            gap: 10px;

            &.card-span {
                grid-column: span 2;
            }

            &.active {
                border: 2px solid #3A809E;

                // .MuiSvgIcon-root {
                //     color: #3A809E;
                // }

                .count {
                    color: #3A809E;
                }

                .details {
                    color: #3A809E;
                }
                .explore {
                    border-top-right-radius: 13px;
    
                }
            }
            img{
                height: 50px;
                width: 50px;
            }

            .explore {
                position: absolute;
                right: 0px;
                top: 0px;
                color: $white;
                // border: 2px solid $silverChalice;
                // border-radius: 100px;
                padding: 0px 0px 0px 8px;
                justify-content: center;
                gap: 0px;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 10px;
                background-color: #8F9195;
                font-size: 14px;

            }
            .total-inventories-explore{
                top: -10px;
                right: -10px;
            }
            .MuiTypography-root{
                font-weight: 400;
            }
            .MuiSvgIcon-root {
                // rotate: 270deg;
                color: $white;
                cursor: pointer;
                height: 20px;
                width: 20px;
                margin-top: 2px;
            }

            .count {
                color: $primaryTextColor;
                font-size: 22px;
                font-weight: 700;
                margin-top: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 140px;
                display: block;
                // margin-bottom: px;
            }

            .details {
                color: #737376;
                font-size: 15px;
                font-weight: 600;
                margin-top: 2px;
                // margin-bottom: px;
            }
            .hand-pointer-wrapper{
                border-radius: 50%;
                height: 40px;
                width: 40px;
                background-color: #F9F9F9;
                border: 1px solid #E4E4DD;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 3px;
                position: relative;
                 
                img{
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
                }
                .pointer-count{
                    position: absolute;
                    top: 0px;
                    right: -10px;
                    height: 20px;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #C7858B;
                    border-radius: 50%;
                    font-family: Segoe UI;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 17.29px;
                    color:$white;
                }
            }
        }
    }

.unread-message-wrapper{
    width: 450px;
    .dashboard__messageBox {
        background: $white;
        // border: 1px solid #E4E4DD;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px #0000001A;

        &--title {
            display: flex;
            justify-content: space-between;
            padding: 15px;

            h4 {
                color: $primaryTextColor;
                font-size: 20px;
                font-weight: 700;
            }

            .btn-link {
                color: #9747FF;
                font-weight: 600;
                font-size: 15px;
            }

            .msg-count {
                .count {
                    border-radius: 100px;
                    background-color: #525E9D;
                    color: $white;
                }
            }
        }

        &--wrapper {
            padding: 15px;
            border-bottom: 1px solid #D9D9D9;
            // border-top: 1px solid #D9D9D9;
            &:last-child{
                border-bottom:unset;
            }

            .user {
                &__avatar {
                    height: 46px;
                    width: 46px;
                    background: #E4F7ED;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 700;
                    font-size: 24px;
                    color: #9747FF;
                }

                &__name {
                    font-weight: 700;
                    font-size: 16px;
                    color: $primaryTextColor;
                }

                &__companyname {
                    color: #737376;
                    font-size: 13px;
                    font-weight: 700;
                }

                &__message {
                    font-weight: 700;
                    font-size: 14px;
                    color: $primaryTextColor;
                    display: -webkit-box;           /* Enables the flexbox container for truncation */
    -webkit-line-clamp: 2;          /* Limits the text to 2 lines */
    -webkit-box-orient: vertical;   /* Specifies the box orientation as vertical */
    overflow: hidden;               /* Hides the overflowing content */
    text-overflow: ellipsis;        /* Adds ellipsis when the text exceeds */
    max-height: 3em;                /* Restricts the height to show 2 lines */
    line-height: 1.5em;
                }

                &__reqType {
                    color: #525E9D;
                    font-size: 13px;
                    font-weight: 600;

                    img {
                        margin-right: 2px;
                    }
                }

                &__requestTime {
                    color: #737376;
                    font-size: 13px;
                    font-weight: 700;
                }
            }
        }

        &--msg {
            padding: 15px;
            border-top: 1px solid #D9D9D9;
            // border-bottom: 1px solid #D9D9D9;
        }
    }   
    .recentRequests__table {
        width: 100%;

        thead {
            background: #F0F0F0;
            border-radius: 8px;
            z-index: 10;
            tr{
                border-radius: 8px;
            th {
                padding: 17px 12px;
                color: #6F6F6F;
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;
            }
        }
        }

        tbody {
            tr{
                border-bottom: 1px solid #E4E4DD;
            td {
                padding: 12px;
                color:$primaryTextColor;
                white-space: nowrap;
                font-family: Segoe UI;
                font-size: 14px;
                font-weight: 400;
                line-height: 18.62px;


                &:nth-child(1){
                font-size: 14px;
                font-weight: 400;
                line-height: 18.62px;
                color: #9747FF;

                }
                .user-img-wrapper{
                    
                    height: 40px;
                    width: 40px;
                    img{
                        object-fit: contain;
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }
        }
    }
}
}
.recent-requests-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #f2f2f2; // Gray background for table header
    }
}

.request__message {
    margin-bottom: 10px;
}

.message-list {
    list-style: none;
    padding: 0;
}

.message-item {
    margin-bottom: 5px;
}
.dashboard__messageBox-wrapper{
    background: #FFFFFF;
    // height: calc(100vh - 165px);
    // border: 1px solid #E4E4DD;
    border-radius: 10px;
    height: 605px;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .msg-inner {
        overflow-y: auto;
        height: 555px;
    }
}
.dashboard-custom-table-outter {
    .table-responsive{
        height: 565px;
        overflow-y: auto;
}
.footer-pagination{
    .page-number{
        font-family: Segoe UI;
font-size: 14px;
font-weight: 400;
line-height: 18.62px;
color: $primaryTextColor;
    }
    .navigation-btn{
        background-color: #FFFFFF;
        color: #1C1B1F;
        border: none;
    .left-tab-icon{
        transform: rotate(-180deg);
    }
    &:hover{
        background-color: #1C1B1F;
        color: #FFFFFF;
    }
}
}
}
.dashboard{
.dashboard-custom-table-outter{
    .dashboard__inner{
        // margin-top: 20px !important;
            .company-dashboard-data-grid{
                .css-1ej0q3x{
height: 564px;
.MuiDataGrid-footerContainer {
    justify-content: unset;
    .MuiTablePagination-root {
        overflow: hidden;
        .MuiToolbar-root {
        padding-left: unset;
        p {
            margin-bottom: 0px;
            font-family: Segoe UI;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.62px;
            text-align: left;
            color: #333333;
        }
    }
}
}
               }
            }
}
}
}
.skeleton-loader {
    width: 100%;
    height: 100%;
    min-height: 300px; /* Adjust based on your layout */
  }
  .dashboard--msg-table--container{
    background-color: #FFFFFF;
    border-radius: 10px;
    .dashboard-custom-table-outter{
        margin-right: 48px;
        &.msg-box-open{
            margin-right: 490px;
        }
    }
  .unread-msg-wrapper{
    background-color: #97AEB8;
    padding-inline: 10px;
    padding-bottom: 10px;
    border-radius: 0 9px 9px 0;
    height: 616px;
    z-index: 30;
    .dashboard__messageBox--title{
        display: flex;
        justify-content: space-between;
        padding: 15px;
        padding-block: 10px;

        h4 {
            color: $white;
            font-size: 20px;
            font-weight: 700;
        }

        .btn-link {
            color: #9747FF;
            font-weight: 600;
            font-size: 15px;
        }

        .msg-count {
            margin-top: 3px                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             ;
            .count {
                border-radius: 100px;
                background-color: #ceae5a;
                color: $white;
            }
        }
    }
  }
  .dashboard__inner{
    padding: 12px 20px;
  .data-grid{
    .MuiDataGrid-main{
        .MuiDataGrid-topContainer{
            .MuiDataGrid-columnHeaders{
                .MuiDataGrid-row--borderBottom{
                    height: 40px !important;
                    border-radius: 8px;
                    .MuiDataGrid-columnHeader{
                        height: 40px !important;
                        border-bottom: unset;
                    }
                }
            }
        }
        .MuiDataGrid-scrollbar {
            &.MuiDataGrid-scrollbar--vertical{
            top: 40px;
        }
    }
        .MuiDataGrid-virtualScrollerContent{
            height: auto !important;
        .MuiDataGrid-row{
            height: 50px !important;
            min-height: 50px !important;
            max-height: 50px !important;
            --height:50px !important;
        .MuiDataGrid-cell{
            height: 50px;
            .lot-id-list-wrapper {
                overflow-x: auto;
                padding-bottom: 5px;

                &::-webkit-scrollbar {
                    height: 3px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #D9D9D9;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }

                .lot-id-item {
                    font-family: Segoe UI;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18.62px;
                    letter-spacing: 0px;
                    color: #333333;
                    background-color: #F7F7F7;
                    border: 1px solid #E4E4DD;
                    padding: 3px;
                }
            }
        }
    }
    }
    }
  }
}
}
.msg-count {
    .count {
        border-radius: 100px;
        background-color: #ceae5a;
        color: $white;
    }
}
.msg-inner{
    overflow-y: auto;
    height: 500px;
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}