.faq-wrapper {
    background-color: #FFFFFF;
    .aside-section {
        .faq {
            &-list {
                list-style-type:none;
                padding-left: unset;
                top: 0px;
                padding-top: 50px;
                .faq-list-item {
                    font-family: Segoe UI;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0%;
                    color: #6F6F6F;
                    text-transform: capitalize;
                    margin-block: 10px;

                    .faq-list-item-link {
                        color: inherit;
                        text-decoration: unset;
                        display: flex;
                        align-items: start;
                        gap: 10px;
                        svg{
                            display: none;
                            margin-top: 2px;
                        }
                    }

                    &.active {
                        color: #3A809E;
                        .faq-list-item-link {
                            
                            svg{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .detail-section {
        .faq-detail-inner {
            padding-top: 50px;
    //         position: sticky;
    // top: 50px;
            .faq-section-wrapper {
                border-bottom: 1px solid #D9D9D9;
                margin-bottom: 30px;
                padding-bottom: 30px;

                .section-heading {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 26px;
                    letter-spacing: 0px;
                    color: #242424;
                }

                .accordion {

                    .accordion-item {
                        background: #F0F0F0;
                        padding: 20px;
                        border-radius: 10px;
                        border: 1px solid #F2F2F2;
                        margin-block: 15px;

                        &:first-child {
                            margin-top: unset;
                        }

                        &:last-child {
                            margin-bottom: unset;
                        }

                        .accordion-header {
                            button {
                                background-color: transparent;
                                box-shadow: unset;
                                font-family: Segoe UI;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 22px;
                                letter-spacing: 0px;
                                text-transform: uppercase;
                                color: #242424;
                                padding: 0px;
                                padding-right: 10px;

                                &.collapsed {
                                    &::after {
                                        background-image: url("../../assets/images/Plus.png");
                                        transform: unset;
                                    }
                                }

                                &::after {
                                    background-image: url("../../assets/images/Minus.png");
                                    transform: unset;
                                }
                            }
                        }

                        .accordion-collapse {
                            .accordion-body {
                                background-color: #FFFFFF;
                                font-family: Segoe UI;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 25px;
                                text-align: left;
                                text-underline-position: from-font;
                                text-decoration-skip-ink: none;
                                color: #242424;
                                margin-top: 15px;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:991px) {
    .aside-section{
        display: none;
    }
}