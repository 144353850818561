.notification-wrapper {
    min-height: calc(-316px + 100vh);
    background-color: #F8F8F8;

    .notification-header {
        padding-block: 15px;
        border-bottom: 1px solid #D9D9D9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .heading {
            font-family: Segoe UI;
            font-weight: 700;
            font-size: 24px;
            leading-trim: Cap height;
            line-height: 22px;
            letter-spacing: 0px;
            color: #242424;
            margin-bottom: 0;
        }

        .search-input-wrapper {
            margin-left: auto;

            input {
                border: 1px solid #E4E4DD;
                background-color: #FFFFFF;
                border-radius: 100px;
                padding: 10px 10px 10px 45px;
                font-family: Segoe UI;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0px;
                color: #6F6F6F;
                max-width: 300px;

                &::placeholder {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    letter-spacing: 0px;
                    color: #6F6F6F;
                }
            }
        }
    }

    .notification-list-wrapper {
        margin-top: 20px;

        .notification-list-item-wrapper {
            background-color: #FFFFFF;
            padding: 15px 20px;
            border-radius: 10px;
            margin-bottom: 10px;

            .notification-item-heading {
                font-family: Segoe UI;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0px;
                vertical-align: bottom;
                color: #242424;
                margin-bottom: 3px;
            }

            .notification-msg {
                font-family: Segoe UI;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0px;
                vertical-align: bottom;
                color: #242424;
                margin-bottom: 3px;
            }

            .notification-time {
                display: flex;
                align-items: center;
                gap: 5px;
                font-family: Segoe UI;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0px;
                vertical-align: bottom;
                color: #6F6F6F;
            }

            &.read-notification {
                .notification-item-heading {
                    font-weight: 400;
                }
            }
        }
    }
}

@media (max-width:1399px) {
    .notification-wrapper {
        min-height: calc(-266px + 100vh);
    }
}