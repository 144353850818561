@import '../../sass/style.scss';

.no-record {
    text-align: center;
    margin-top: 20px;
}

.company-dashboard {
    background-color: $brightGray;
    // border-radius: 35px 35px 0px 0px;

    &__uploadInvBanner {
        .banner {
            position: relative;
            border-radius: 10px;
            background-image: url('./../../assets/images/products/BarrelFillImage.png');
            background-size: cover;
            background-position: center;
            min-height: 325px;
            display: flex;
            align-items: center;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                // background-color: rgba(0, 0, 0, 0.5); 
                background: linear-gradient(356.85deg, rgba(255, 255, 255, 0) -0.62%, rgba(1, 1, 1, 0.5) 75.39%);
                z-index: 1;
                border-radius: 10px;
            }

            .banner-content {
                position: relative;
                z-index: 2;

                h4 {
                    line-height: 42px;
                }

                // .btn {
                //     border-color: #FFF;
                //     color: #FFF;

                //     &:hover,
                //     &:active {
                //         background-color: unset;
                //         color: #FFF;
                //         border: 2px solid #FFF;
                //     }
                // }
            }
        }
    }

    // &__companyProfile {
    //     height: calc(100vh - 250px);
    //     max-width: 300px;
    //     min-width: 300px;
    //     margin-top: 55px;
    //     margin-bottom: 3rem;
    //     // overflow: hidden;
    //     position: fixed;
    //     z-index: 1;

    //     .profile {
    //         height: 100%;
    //         background: #F0F0F0;
    //         border-radius: 20px;

    //         .company-details {
    //             transform: translateY(-55px);

    //             .company-name {
    //                 font-weight: 700;
    //                 font-size: 18px;
    //             }
    //         }

    //         .list-group {
    //             .list-group-item {
    //                 background-color: $transparent;
    //                 display: flex;
    //                 align-items: center;
    //                 border-left-width: 0px;
    //                 border-right-width: 0px;
    //                 border-radius: 0px;

    //                 // &:last-child {
    //                 //     border-bottom-width: 0px;
    //                 // }

    //                 .info {
    //                     .sub-title {
    //                         color: #496673;
    //                         font-size: 13px;
    //                         font-weight: 700;
    //                     }

    //                     .detail {
    //                         color: #242424;
    //                         font-size: 15px;
    //                         font-weight: 700;
    //                     }
    //                 }
    //             }
    //         }

    //         .collapse-btn {
    //             background: $white;
    //             border-radius: 50%;
    //             height: 40px;
    //             width: 40px;
    //         }
    //     }
    // }

    &__user {
        &--title {
            color: $primaryTextColor;
            font-weight: 600;
            font-size: 25px;
        }

        &--companyInfo {
            .dropdown {
                .dropdown-toggle {
                    &::after {
                        content: none;
                    }
                }

                .btn-info {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 40px;
                    background-color: $white;
                    border-radius: 50%;
                    border: none;
                    outline: none;
                }

                .dropdown-menu {
                    box-shadow: 0px 10px 30px 0px #00000026;
                    border-radius: 25px;
                    background-color: $white;
                    min-width: 300px;
                    max-width: 300px;
                }

                .list-group {
                    .list-group-item {
                        background-color: $transparent;
                        display: flex;
                        align-items: center;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        border-radius: 0px;

                        &:first-child {
                            border-top-width: 0px;
                        }

                        &:last-child {
                            border-bottom-width: 0px;
                        }

                        .company-details {
                            .company-name {
                                font-weight: 700;
                                font-size: 18px;
                            }
                        }


                        .info {
                            .sub-title {
                                color: #496673;
                                font-size: 13px;
                                font-weight: 700;
                            }

                            .detail {
                                color: #242424;
                                font-size: 15px;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
        .company-dashboard-user__nav--tabs-wrapper{
            height: 45px;
            background-color: $white;
            border-radius: 50px;
            .nav-btn{ 
                padding: 10px 34px;
                font-family: Segoe UI;
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
                text-align: left;
                border-radius: 50px;
                background-color: transparent;
                color: #737376;
                border: none;
                transition: 0.3s all;
                height: 45px;
                &.active{
                    background-color: #0C3344;
                    color: $white;
                    transition: 0.3s all;
                }
            }
        }
    }
    &-user__nav--tabs-wrapper{
        background-color: $white;
    }

    &__inner {
        gap: 32px;
        // background: $white;
        padding: 0px;
        border-radius: 25px;
        .dashboard__uploadInvBanner{
            height: 50%;

        }
        // .company-dashboard__messageBox-wrapper{
        //     &.owner-data{
        //     height: calc(50% - 20px);
        // }
        // &.buyer-data{
        //     height: calc(100% - 20px);
        // }
        
        // }
        .dashboard__messageBox-wrapper{
            &.buyer-data{
                height: calc(100% - 20px);
            }
        }
    }
    

    &__data {
        &.owner-data{
            grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
        }
        display: grid;
        &.buyer-data{
            grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
        }
        
        gap: 10px;

        // @include media-between(xl, xxl) {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @include media-between(md, xl) {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @include media-between(sm, md) {
        //     grid-template-columns: repeat(3, 1fr);
        // }

        // @include media-max(sm) {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        &--card {
            background: $white;
            position: relative;
            display: flex;
            flex-direction: row;
            flex: 1 1 calc(20% - 10px);
            box-shadow: 0px 4px 20px 0px #0000001A;
            padding: 27px 15px;
            border-radius: 15px;
            gap: 5px;
            // padding-top: 25px;

            img{
                height: 40px;
                width: 40px;
            }

            // &.card-span {
            //     grid-column: span 2;
            // }

            &.active {
                border: 2px solid #3A809E;

                // .MuiSvgIcon-root {
                //     color: #3A809E;
                // }

                .count {
                    color: #3A809E;
                }

                .details {
                    color: #3A809E;
                }
            }

            .explore {
                position: absolute;
                right: 15px;
                color: $white;
                // border: 2px solid $silverChalice;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 0px 0px 0px 8px;
                justify-content: center;
                gap: 0px;
                position: absolute;
                top: 0;
                right: 0;
                background-color: #8F9195;
                font-weight: 400;
            }

            .MuiSvgIcon-root {
                // rotate: 270deg;
                color: $white;
                cursor: pointer;
                height: 20px;
                width: 20px;
                margin-top: 4px;
            }

            .count {
                color: $primaryTextColor;
                font-size: 18px;
                font-weight: 23.94px;
                font-weight: 700;
                // margin-top: 12px;
                // margin-bottom: px;
            }

            .details {
                color: #737376;
                font-size: 15px;
                line-height: 19.95px;
                font-weight: 600;
                margin-top: 3px;
                // margin-bottom: px;
            }
        }
    }

    .unread-message-wrapper{
        // width: 450px;

    .dashboard__messageBox {
        background: $white;
        // border: 1px solid #E4E4DD;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px #0000001A;

        &--title {
            display: flex;
            justify-content: space-between;
            padding: 15px;

            h4 {
                color: $white;
                font-size: 20px;
                font-weight: 700;
            }

            .btn-link {
                color: #9747FF;
                font-weight: 600;
                font-size: 15px;
            }

            .msg-count {
                .count {
                    border-radius: 100px;
                    background-color: #ceae5a;
                    color: $white;
                }
            }
        }

        &--wrapper {
            padding: 15px;
            border-top: 1px solid #D9D9D9;

            .user {
                &__avatar {
                    height: 46px;
                    width: 46px;
                    background: #E4F7ED;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 700;
                    font-size: 24px;
                    color: #9747FF;
                }

                &__name {
                    font-weight: 700;
                    font-size: 16px;
                    color: $primaryTextColor;
                }

                &__companyname {
                    color: #737376;
                    font-size: 13px;
                    font-weight: 700;
                }

                &__message {
                    font-weight: 700;
                    font-size: 14px;
                    color: $primaryTextColor;
                }

                &__reqType {
                    color: #525E9D;
                    font-size: 13px;
                    font-weight: 600;

                    img {
                        margin-right: 2px;
                    }
                }

                &__requestTime {
                    color: #737376;
                    font-size: 13px;
                    font-weight: 700;
                }
            }
        }

        &--msg {
            padding: 15px;
            border-top: 1px solid #D9D9D9;
            // border-bottom: 1px solid #D9D9D9;
        }
    }
}

    .recentRequests__table {
        width: 100%;

        thead {
            background: #F0F0F0;
            border-radius: 8px;

            th {
                padding: 17px 12px;
                color: #6F6F6F;
                font-size: 14px;
                font-weight: 400;
            }
        }

        tbody {
            td {
                padding: 12px;
            }
        }
    }
}

.recent-requests-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #f2f2f2; // Gray background for table header
    }
}

.request__message {
    margin-bottom: 10px;
}

.message-list {
    list-style: none;
    padding: 0;
}

.message-item {
    margin-bottom: 5px;
}
.company-dashboard__messageBox-wrapper{
    background: #FFFFFF;
    // height: calc(100vh - 165px);
    // border: 1px solid #E4E4DD;
    border-radius: 10px;
    height: 605px;
    overflow-y: hidden;
    width: 470px;
    // margin-top: 40px;
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
.user-header__note{
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.28px;
    text-align: left;
    color: $white;
    max-width: 500px;
}
.dashboard-custom-table-outter{
    .dashboard__inner{
        padding-bottom: 0px;
        .row{
            margin-top: 0px !important;
            .company-dashboard-data-grid{
                height: 565px;
            }
        }
        .table-responsive{
            &.company-dashboard-data-grid{
                height: 565px;
            .css-7li2lt{
                height: 100%;
                .data-grid{
                    .MuiDataGrid-main{
                        .MuiDataGrid-virtualScroller{
                            .MuiDataGrid-virtualScrollerContent{
                                .MuiDataGrid-virtualScrollerRenderZone{
                                    .MuiDataGrid-cell{
                                        // font-size: 14px;
                                        // line-height: 18.62px;
                                        color: #333333;
                                        .user-img-wrapper{
                                            img{
                                                height: 40px;
                                                width: 40px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .MuiDataGrid-footerContainer{
                        justify-content: unset;
                        // width: fit-content;
                        .MuiTablePagination-root{
                            overflow: hidden;
                            .MuiToolbar-root{
                                padding-left: unset;  
                                p{
                                  margin-bottom: 0px;
                                  font-family: Segoe UI;
                                  font-size: 14px;
                                  font-weight: 400;
                                  line-height: 18.62px;
                                  text-align: left;
                                  color: #333333;
                                }
                              }
                          
                        }
                        .MuiTablePagination-actions{
                            .MuiButtonBase-root{
                                .MuiSvgIcon-root{
                                    // &svg{
                                        // font-size: 12px;
                                        fill: #1C1B1F;
                                    // }
                                }
                            }
                        }
                    }
    
                }
            }
        }
        .css-7li2lt{
            height: 100%;
            .data-grid{
                .MuiDataGrid-main{
                    .MuiDataGrid-virtualScroller{
                        .MuiDataGrid-virtualScrollerContent{
                            .MuiDataGrid-virtualScrollerRenderZone{
                                .MuiDataGrid-cell{
                                    // font-size: 14px;
                                    // line-height: 18.62px;
                                    color: #333333;
                                    .user-img-wrapper{
                                        img{
                                            height: 40px;
                                            width: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .MuiDataGrid-footerContainer{
                    justify-content: unset;
                    // width: fit-content;
                    .MuiTablePagination-root{
                        overflow: hidden;
                        .MuiToolbar-root{
                            padding-left: unset;  
                            p{
                              margin-bottom: 0px;
                              font-family: Segoe UI;
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 18.62px;
                              text-align: left;
                              color: #333333;
                            }
                          }
                      
                    }
                    .MuiTablePagination-actions{
                        .MuiButtonBase-root{
                            .MuiSvgIcon-root{
                                // &svg{
                                    // font-size: 12px;
                                    fill: #1C1B1F;
                                // }
                            }
                        }
                    }
                }

            }
        }
        }
        .css-1ej0q3x{
            height: 100%;
            .MuiDataGrid-footerContainer{
                justify-content: unset;
                // width: fit-content;
                .MuiTablePagination-root{
                    overflow: hidden;
                    .MuiToolbar-root{
                        padding-left: unset;  
                        p{
                          margin-bottom: 0px;
                          font-family: Segoe UI;
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 18.62px;
                          text-align: left;
                          color: #333333;
                        }
                      }
                  
                }
                .MuiTablePagination-actions{
                    .MuiButtonBase-root{
                        .MuiSvgIcon-root{
                            // &svg{
                                // font-size: 12px;
                                fill: #1C1B1F;
                            // }
                        }
                    }
                }
            }
        }
    }
}
.MuiDataGrid-footerContainer {
    &.css-wop1k0-MuiDataGrid-footerContainer {
        justify-content: unset;

        .MuiTablePagination-root {
            overflow: hidden;
            .MuiToolbar-root {
                padding-left: unset;

                p {
                    margin-bottom: 0px;
                    font-family: Segoe UI;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18.62px;
                    text-align: left;
                    color: #333333;
                }
            }
        }
    }
}
.msg-count {
    .count {
        border-radius: 100px;
        background-color: #ceae5a;
        color: $white;
    }
}
.msg-inner{
    overflow-y: auto;
    height: 555px;
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #D9D9D9;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

}
.css-1ej0q3x {
    height: 494px;  
    width: 100%;
    overflow-x: auto;
}